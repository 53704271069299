body {
  background-color: white;
  margin: 0px;
  font-family: "Poppins";
  font-size: 12px;
}
li {
  margin-bottom: 12px;
}
.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.betweenRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.aroundRow {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.startRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.endRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.card {
  color: white;
  background: #212121;
  overflow: hidden;
  margin-bottom: 20px;
  font-size: 16px;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.cardTitle {
  font-family: "Bona Nova";
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 30px;
  line-height: 150%;
}
.tableTitle {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 3px;
  color: #c1c1c1;
}
.tableHeader {
  font-size: 24px;
  font-weight: 600;
  color: white;
}

.tableValue {
  font-size: 24px;
  font-weight: 500;
  color: white;
}

.cardDesc {
  font-size: 16px;
  font-weight: 500;
  font-family: "Noto Sans";
}
a {
  color: #1d6fff;
  text-decoration: none;
}

.lightCard {
  background: #283246;
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0;
  border-radius: 10px;
  padding: 20px 35px;
}

.icon {
  width: 36px;
  height: 36px;
  margin: 2px;
  margin-right: 5px;
  vertical-align: middle;
  object-fit: contain;
}

.smIcon {
  width: 20px;
  height: 20px;
  margin: 2px;
  margin-right: 5px;
  vertical-align: middle;
  object-fit: contain;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
.analyticsIcon {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  object-fit: contain;
  margin-bottom: 10px;
}
.line {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  margin: 0 auto;
  object-fit: contain;
}

.cardLine {
  border: 3px solid red;
  margin: 20px 0;
}
.field {
  margin: 50px auto;
  text-align: center;
  max-width: 1000px;
}

.icon {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin-right: 10px;
  object-fit: contain;
}
.smIcon {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-right: 4px;
  object-fit: contain;
}
.lgIcon {
  width: 50px;
  height: 50px;
  vertical-align: middle;
  margin-right: 20px;
  object-fit: contain;
}
.block {
  margin-top: 60px;
}

.valueCard {
  background: linear-gradient(transparent, #f53838);
  box-shadow: 5px 13px 12px 4px rgba(225, 0, 0, 0.4);
  border: 1.5px solid #f53838;
  color: white;
  text-align: center;
  font-size: 22px;
  overflow: hidden;
  border-radius: 15px;
  margin: 10px;
  padding: 30px;
  min-height: 350px;
}

.projectCard {
  color: white;
  overflow: hidden;
  text-align: left;
  height: 200px;
  border-radius: 15px;
  margin: 10px;
  padding: 30px;
}
.alertCard {
  background-color: rgba(255, 255, 255, 0.3);
  color: black;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px;
  padding: 15px;
}
.cardBgHeader {
  color: #e93951;
  font-family: "Tomorrow";
  font-size: 32px;
  font-weight: 700;
}
.cardHeader {
  color: #e93951;
  font-family: "Sarpanch";
  font-size: 20px;
  line-height: 1.2;
  padding: 5px;
  font-weight: 700;
}
.fieldHeader {
  font-size: 32px;
  font-weight: 600;
}
.footerHeader {
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 48px;
}
.footerLink {
  font-size: 30px;
  font-weight: 600;
  font-family: "Sarpanch";
  margin-right: 87px;
  color: #fff;
  > a {
    color: #fff;
    text-decoration: none;
  }
}
.cardValue {
  color: white;
  font-family: "Sarpanch";
  font-size: 34px;
  line-height: 1.2;
  padding: 5px;
  font-weight: 700;
}
.cardContent {
  color: white;
  font-size: 18px;
  line-height: 1.2;
  padding-bottom: 15px;
}
.cardLgValue {
  color: white;
  font-size: 36px;
  padding-bottom: 25px;
  font-weight: 700;
}
.tab {
  background-color: white;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}
.lightTab {
  background-color: rgba(225, 225, 225, 0.3);
  overflow: hidden;
  border-radius: 10px;
  margin: "0 10px";
  vertical-align: bottom;
  padding: 20px 20px;
}
.title {
  text-align: center;
  font-size: 35px;
  vertical-align: middle;
  font-weight: 800;
  margin: 25px auto;
  line-height: 1.2;
  max-width: 800px;
}
.subTitle {
  line-height: 1.2;
  font-size: 24px;
  font-family: "Noto Sans";
  margin-bottom: 4px;
  font-weight: 400;
  margin-top: 15px;
}
.cardContainer {
  padding: 15px;
}
.coloredTitle {
  color: #e84242;
  font-size: 20px;
  margin-top: 15px;
  font-weight: 600;
}
.coloredText {
  color: #e84242;
}
.dashboardHeader {
  font-size: 24px;
  vertical-align: middle;
  font-weight: 600;
  margin-bottom: 20px;
}
.listHeader {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
.listContent {
  text-align: right;
  font-size: 22px;
  font-weight: 600;
}
.valueHeader {
  font-size: 16px;
  line-height: 1.5;
  color: white;
  opacity: 0.6;
  font-weight: 600;
  margin-bottom: 15px;
}
.value {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
  color: white;
}
.title {
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.rightValue {
  font-size: 20px;
  font-weight: 600;
  color: white;
  text-align: right;
}

.tooltip {
  padding: 0;
  margin-left: 10px;
  margin-top: -2px;
}

.text {
  font-size: 16px;
  line-height: 1.5;
}

.labelLink {
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
}

.cardTitleItem {
  width: 20%;
}
.w20 {
  width: 20%;
}
.w15 {
  width: 15%;
}
.w10 {
  width: 10%;
}
.w30 {
  width: 30%;
}
.w40 {
  width: 40%;
}

.rightText {
  float: right;
  font-weight: 600;
  margin-top: 20px;
}

.speedo-segment {
  margin: 10px;
  padding: 10px;
}

.InfiniteCarouselArrowIcon {
  border-color: black;
}
.MuiSelect-icon {
  color: white;
}
