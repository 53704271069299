.parallax-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: transform; /* 优化性能 */
  flex-direction: column;
}

.message {
  font-size: 2rem;
  color: white;
  font-family: "Tomorrow", sans-serif;
}
